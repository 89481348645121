<script>
	import vue2Dropzone from "vue2-dropzone"
	import "vue2-dropzone/dist/vue2Dropzone.min.css"
	import "vue-form-wizard/dist/vue-form-wizard.min.css"
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"
	import Multiselect from "vue-multiselect"
	import moment from 'moment'
	import DatePicker from "vue2-datepicker"

	/**
	 * Form-upload component
	 */
	export default {
		components: {
			vueDropzone: vue2Dropzone,
			Layout,
			PageHeader,
			Multiselect,
			DatePicker,
		},
		page: {
			title: "Form File Upload",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		data() {
			return {
				title: "Form File Upload",
				items: [
					{
						text: "Forms",
						href: "/",
					},
					{
						text: "Form File Upload",
						active: true,
					},
				],
				corporateId: '',
				loading: false,
				candidateName: "",
				number: "",
				data: [],
				journey: null,
				journeyList: [],
				offerExpiry: "",
				dropzoneOptions: {
					url: process.env.VUE_APP_BASE_URL + "onboarding/upload-offer-letter", //@TODO change this URL to prod url
					thumbnailWidth: 150,
					maxFilesize: 0.5,
					maxFiles: 1,
					addRemoveLinks: true,
					headers: {
						'Authorization': "Bearer "+localStorage.getItem('authToken'),
					},
					paramName: function () {
						return "offerLetter";
					},
					/*success: function(file, response) {
						console.log(response)
						//file.previewElement.parentNode.removeChild(file.previewElement)
					}*/
				},
			};
		},
		mounted() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getJourneyList()
		},
		methods: {
			onPdfUpload(file, response) {
				this.data = response.data.onboardEmployeeDetail
				this.data.joiningDate = moment(this.data.joiningDate).format("DD-M-yyyy")
			},
			getJourneyList: function() {
				this.axios.post('onboarding-journey/get-journey-list', {
					adminId: this.$storageData.id,
					corporateId: this.$storageData.corporateId,
					branchId: this.$storageData.branchId,
				}).then((result) => {
					this.journeyList = [];
					for (var i = 0;i < result.data.data.length;i++) {
						this.journeyList.push({
							journeyId: result.data.data[i].journeyId,
							journeyName: result.data.data[i].journeyName,
						});
					}
					this.journey = this.journeyList[0]
				}).catch((error) => {
					console.log(error);
				});
			},
			handleClick: function() {
				this.axios.post('onboarding/update-onboard-employee-data', {
					onboardEmployeeId: this.data._id,
					onboardJourneyId: this.journey.journeyId,
					corporateId: this.$storageData.corporateId,
					branchId: this.$storageData.branchId,
					name: this.data.name,
					phoneNumber: this.data.phoneNumber,
					annualCtc: this.data.annualCtc,
					joiningDate: this.data.joiningDate,
					designation: this.data.designation,
					offerExpiry:this.data.offerExpiry,
				}).then((result) => {
					let statusCode = result.data.responseCode
					if (statusCode === 200) {
						window.location.reload()
					}
					if (statusCode === 400) {
						this.$toast.error(result.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
		middleware: "authentication",
	};
</script>
<style scoped>
	.vue-dropzone > .dz-preview .dz-remove {
		margin-right: 15px;
		color: #ea2828;
		z-index: 1000;
		border-color: #ea2828;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Upload Offer letter</h4>
						<!-- file upload -->
						<vue-dropzone @vdropzone-success="(file, response) => onPdfUpload(file, response)"
							ref="myVueDropzone" id="dropzone" :options="dropzoneOptions">
							<div class="dropzone-custom-content">
								<i class="display-4 text-muted bx bxs-cloud-upload"></i>
								<h4>Drop files here or click to upload.</h4>
							</div>
							<!--<i class="mdi mdi-close" data-dz-remove></i>-->
							<img src="@/assets/images/remove.png" alt="" data-dz-remove />
						</vue-dropzone>
						<!-- Candidate details Form -->
						<div class="row">
							<div class="col-lg-6">
								<div class="form-group mb-4">
									<label class="col-md-3 col-form-label" for="userName">Candidate name</label>
									<div class="col-md-12">
										<input id="userName" type="text" class="form-control" name="userName" v-model="data.name" />
									</div>
								</div>
								<div class="form-group mb-4">
									<label class="col-md-3 col-form-label" for="ctc">Annual CTC</label>
									<div class="col-md-12">
										<input id="ctc" type="text" name="ctc" class="form-control" v-model="data.annualCtc" />
									</div>
								</div>
								<div class="form-group mb-4">
									<label class="col-md-3 col-form-label" for="designation">Designation</label>
									<div class="col-md-12">
										<input id="designation" type="text" name="ctc" class="form-control" v-model="data.designation" />
									</div>
								</div>
							</div>
							<!-- end col -->
							<div class="col-lg-6">
								<div class="form-group mb-4">
									<label class="col-md-9 col-form-label" for="number">WA Number(for eg: 919876543210)</label>
									<div class="col-md-12">
										<input id="number" type="number" name="number" class="form-control" maxlength="10" v-model="data.phoneNumber" />
									</div>
								</div>
								<div class="form-group mb-4">
									<label class="col-md-3 col-form-label" for="date">Joining Date</label>
									<div class="col-md-12">
										<input id="joiningDate" type="text" name="joiningDate" class="form-control" v-model="data.joiningDate" />
									</div>
								</div>
								<div class="form-group mb-4">
									<label class="col-md-3 col-form-label" for="expiry">Offer Expiry</label>
									<div class="col-md-12">
										<date-picker v-model="data.offerExpiry" format="DD MMM Y" append-to-body lang="en" />
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="form-group mb-4">
									<label for="journeyName" class="col-md-3 col-form-label">Journey Name</label>
									<div class="col-md-12">
										<multiselect id="journeyName" class="helo" v-model="journey" :options="journeyList" placeholder="Select Journey" track-by="journeyName" label="journeyName" :allow-empty="false" :multiple="false">
											<template slot="singleLabel" slot-scope="{ option }">{{ option.journeyName }}</template>
										</multiselect>
									</div>
								</div>
							</div>
						</div>
						<!-- end row -->
						<button type="submit" class="col-3 m-2 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading" @click="handleClick">Continue</button>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end card -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
	</Layout>
</template>
